import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment/moment'
import Select from 'react-select'
import { addArrayCreoProfits, resetCreoProfits } from '../../store/creoProfitsReducer'
import { addArrayFilteredCreoProfits, resetFilteredCreoProfits } from '../../store/filteredCreoProfitsReducer'
import { getCreoProfits, getCreoProfitsByMonth } from '../../http/creoProfitsApi'
import './CreoProfits.css'
import Loader from '../Loader'


const CreoProfits = () => {
    const currentMonth = moment().month() + 1
    const currentYear = moment().year()
    console.log(currentMonth, currentYear)
    const dispatch = useDispatch()
    const userStore = useSelector(state => state.user.user)
    const creoProfitsStore = useSelector(state => state.creoProfits.creoProfits)
    const filteredCreoProfitsStore = useSelector(state => state.filteredCreoProfits.filteredCreoProfits)
    const [loading, setLoading] = useState(true)
    // const [month, setMonth] = useState(null)
    // const [year, setYear] = useState(null)
    const [creativeNames, setCreativeNames] = useState([])
    const [geos, setGeos] = useState([])
    const [os, setOs] = useState(['ios', 'android'])
    const [selectedMonth, setSelectedMonth] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)
    const [multiOptionCreativeName, setMultiOptionCreativeName] = useState([])
    const [multiOptionGeo, setMultiOptionGeo] = useState([])
    const [multiOptionOs, setMultiOptionOs] = useState([])
    const [monthOptions, setMonthOptions] = useState([])
    const [yearOptions, setYearOptions] = useState([])
    const [groupedOptions, setGroupedOptions] = useState([])
    const [keysToMatch, setKeysToMatch] = useState(['template'])
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' })

    const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    const years = ['2024', '2025']

    function replaceKeysWithProduct(data) {
        data.forEach(obj => {
            const spend = parseFloat(obj.spend) || 0
            const installs = parseFloat(obj.installs) || 0
            const impressions = parseFloat(obj.impressions) || 0

            if (spend !== 0) {
                obj.roi = (obj.profit / spend) * 100
            } else {
                obj.roi = 0
            }

            if (installs !== 0 && impressions !== 0) {
                obj.ipm = obj.installs * 1000 / obj.impressions
            } else {
                obj.ipm = 0
            }

        })

        return data
    }

    function mergeObjectsByKeys(arr, keysToMatch, keysToSum, partialKey) {
        const groups = {}

        arr.forEach(obj => {
            const groupKey = keysToMatch.map(key => {
                if (key === partialKey) {
                    const partialValue = obj[partialKey].slice(0, 6)
                    
                    const existingGroup = Object.keys(groups).find(groupKey => {
                        const groupValues = groupKey.split('|')
                        const partialIndex = keysToMatch.indexOf(partialKey)
                        return groupValues[partialIndex].startsWith(partialValue)
                    })
                    
                    // obj[partialKey] = partialValue;

                    return existingGroup ? existingGroup.split('|')[keysToMatch.indexOf(partialKey)] : partialValue
                }
                return obj[key]
            }).join('|')
            
            if (!groups[groupKey]) {
                groups[groupKey] = { ...obj }
            } else {
                keysToSum.forEach(key => {
                    groups[groupKey][key] += obj[key]
                })
            }
        })
        
        return Object.values(groups)
    }

    useEffect( () => {
        dispatch(resetCreoProfits())
        getCreoProfitsByMonth(currentMonth, currentYear).then(data => {
            if (data.length !== 0) {
                dispatch(addArrayCreoProfits(data))

                const creativeNamesOnly = Array.from(new Set(data.map(el => el.creativeName.slice(0,6))))

                const modifyCreativeNames = creativeNamesOnly.map(element => {
                    return {
                        value: element,
                        label: element,
                        color: '#000'
                    }
                })
                setCreativeNames(modifyCreativeNames.sort((a, b) => a.value > b.value ? 1 : -1))
                
                const geosOnly = Array.from(new Set(data.map(el => el.country)))

                const modifyGeos = geosOnly.map(element => {
                    return {
                        value: element,
                        label: element,
                        color: '#000'
                    }
                })
                setGeos(modifyGeos.sort((a, b) => a.value > b.value ? 1 : -1))

                const modifyOs = os.map(element => {
                    return {
                        value: element,
                        label: element,
                        color: '#000'
                    }
                })
                setOs(modifyOs)

                const modifyMonth = months.map(element => {
                    return {
                        value: element,
                        label: element,
                        color: '#000'
                    }
                })
                setMonthOptions(modifyMonth)

                const modifyYear = years.map(element => {
                    return {
                        value: element,
                        label: element,
                        color: '#000'
                    }
                })
                setYearOptions(modifyYear)

                const groupedOptions = [
                    {
                        label: "Creos",
                        options: modifyCreativeNames
                    },
                    {
                        label: "Geo",
                        options: modifyGeos
                    },
                    {
                        label: "Os",
                        options: modifyOs
                    }
                ];
        
                setGroupedOptions(groupedOptions)
            } 
        })
    }, [])

    const downloadData = () => {
        if (selectedMonth && selectedYear) {
            getCreoProfitsByMonth(selectedMonth, selectedYear).then(data => {
                if (data.length > 0) {
                    dispatch(resetCreoProfits())
                    setCreativeNames([])
                    setGeos([])
                    setOs([])
                    setGroupedOptions([])
                    
                    dispatch(addArrayCreoProfits(data))

                    const creativeNamesOnly = Array.from(new Set(data.map(el => el.creativeName.slice(0,6))))

                    const modifyCreativeNames = creativeNamesOnly.map(element => {
                        return {
                            value: element,
                            label: element,
                            color: '#000'
                        }
                    })
                    setCreativeNames(modifyCreativeNames.sort((a, b) => a.value > b.value ? 1 : -1))
                
                    const geosOnly = Array.from(new Set(data.map(el => el.country)))

                    const modifyGeos = geosOnly.map(element => {
                        return {
                            value: element,
                            label: element,
                            color: '#000'
                        }
                    })
                    setGeos(modifyGeos.sort((a, b) => a.value > b.value ? 1 : -1))
            
                    console.log(os)
                    // const modifyOs = os.map(element => {
                    //     return {
                    //         value: element,
                    //         label: element,
                    //         color: '#000'
                    //     }
                    // })
                    setOs(os)
    
                    console.log(os)
                    const modifyMonth = months.map(element => {
                        return {
                            value: element,
                            label: element,
                            color: '#000'
                        }
                    })

                    const groupedOptions = [
                        {
                            label: "Creos",
                            options: modifyCreativeNames
                        },
                        {
                            label: "Geo",
                            options: modifyGeos
                        },
                        {
                            label: "Os",
                            options: os
                        }
                    ];
                    setGroupedOptions(groupedOptions)
                } else {
                    alert("Нет данных за выбранный месяц и год.")
                }
            })
        } else if (selectedMonth === currentMonth && selectedYear === currentYear) {
            alert("Данные за текущий месяц уже выгружены!")
        } else {
            alert("Пожалуйста, выберите месяц и год.")
        }
    }

    const filterAvailableOptions = (keysToMatch) => {
        const selectedOptions = [...multiOptionCreativeName, ...multiOptionGeo, ...multiOptionOs];
    
        // Фильтруем группы: если в keysToMatch уже есть соответствующий ключ, убираем группу опций
        const newGroupedOptions = [
            {
                label: "Creos",
                options: !keysToMatch.includes('creativeName') ? creativeNames : []
            },
            {
                label: "Geo",
                options: !keysToMatch.includes('country') ? geos : []
            },
            {
                label: "Os",
                options: !keysToMatch.includes('os') ? os : []
            }
        ];
    
        // Обновляем список опций для селектов
        setGroupedOptions(newGroupedOptions)
    }

    useEffect(() => {
        const checkKeys = () => {
            let newKeysToMatch = []

            if (multiOptionCreativeName.length === 0 && multiOptionGeo.length === 0 && multiOptionOs.length === 0) {
                newKeysToMatch = ['template']
            } else {
                multiOptionCreativeName.forEach(option => {
                    if (/^[A-Z]{2}$/.test(option)) {
                        newKeysToMatch.push('country')
                    } else if (/^(ios|android)$/.test(option)) {
                        newKeysToMatch.push('os')
                    } else {
                        newKeysToMatch.push('creativeName')
                    }
                })

                multiOptionGeo.forEach(option => {
                    if (/^[A-Z]{2}$/.test(option)) {
                        newKeysToMatch.push('country')
                    } else if (/^(ios|android)$/.test(option)) {
                        newKeysToMatch.push('os')
                    } else {
                        newKeysToMatch.push('creativeName')
                    }
                })

                multiOptionOs.forEach(option => {
                    if (/^(ios|android)$/.test(option)) {
                        newKeysToMatch.push('os')
                    } else if (/^[A-Z]{2}$/.test(option)) {
                        newKeysToMatch.push('country')
                    } else {
                        newKeysToMatch.push('creativeName')
                    }
                })
        }

        setKeysToMatch(newKeysToMatch)
    }

    checkKeys()
    }, [creoProfitsStore, multiOptionCreativeName, multiOptionGeo, multiOptionOs])

    useEffect(() => {
        currentFilter()
        filterAvailableOptions(keysToMatch)
        setTimeout(() => setLoading(false), 800)
    }, [keysToMatch])

    const colorStyles = {
        control: (styles) => ({ ...styles, backgroundColor: 'white'}),
        option: (styles, {data}) => {
            return { ...styles, color: data.color}
        },
        menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    const currentFilter = () => {
        if (multiOptionCreativeName.length === 0 && multiOptionGeo.length === 0 && multiOptionOs.length === 0) {
            const ready = mergeObjectsByKeys(creoProfitsStore, keysToMatch, ['impressions', 'etcpi', 'installs', 'ipm', 'spend', 'regs', 'deps', 'income', 'profit', 'roi'], 'creativeName');
            const dataWithIncome = replaceKeysWithProduct(ready);
            const sortedArr = dataWithIncome.sort((a, b) => a.creativeName > b.creativeName ? 1 : -1);
            dispatch(addArrayFilteredCreoProfits(sortedArr));
        } else {
            const multiOptions = multiOptionCreativeName.concat(multiOptionGeo).concat(multiOptionOs)
    
            function filterArray(objects, filterStrings, keys) {
                return objects.filter(obj => {
                    return filterStrings.every((str, index) => {
                        const key = keys[index]
            
                        if (key === 'creativeName' && /^CP\d{4}$/.test(str)) {
                            return obj[key] && obj[key].substring(0, 6) === str.substring(0, 6)
                        }
            
                        return obj[key] && obj[key] === str
                    })
                })
            }

            let filteredArray = filterArray(creoProfitsStore, multiOptions, keysToMatch);
            const ready = mergeObjectsByKeys(filteredArray, keysToMatch, ['impressions', 'etcpi', 'installs', 'ipm', 'spend', 'regs', 'deps', 'income', 'profit', 'roi'], 'creativeName');
            const dataWithIncome = replaceKeysWithProduct(ready);
            const sortedArr = dataWithIncome.sort((a, b) => a.creativeName > b.creativeName ? 1 : -1)
            dispatch(addArrayFilteredCreoProfits(sortedArr))
        }
    }

    const handleMonthYearChange = (month, year) => {
        setSelectedMonth(month)
        setSelectedYear(year)
    }

    const handleSort = (key) => {
        let direction = 'ascending'
        
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending'
    }

    if (filteredCreoProfitsStore.length === 0) {
        const sortedData = [...creoProfitsStore].sort((a, b) => {
            if (a[key] < b[key]) {
              return direction === 'ascending' ? -1 : 1
            }
            if (a[key] > b[key]) {
              return direction === 'ascending' ? 1 : -1
            }
            return 0
          })
      
          dispatch(resetCreoProfits())
          dispatch(addArrayCreoProfits(sortedData))
    } else {
        const sortedData = [...filteredCreoProfitsStore].sort((a, b) => {
            if (a[key] < b[key]) {
              return direction === 'ascending' ? -1 : 1
            }
            if (a[key] > b[key]) {
              return direction === 'ascending' ? 1 : -1
            }
            return 0
          })
      
          dispatch(resetFilteredCreoProfits())
          dispatch(addArrayFilteredCreoProfits(sortedData))
    }
    
    setSortConfig({ key, direction })
  }


    return (
        <div className={'form'}>
            {/* {userStore.role === 'TechHelper' || userStore.role === 'User' || userStore.role === 'CreoUnitHelper' ?
                <h3>Нет доступа</h3>
            :
                <> */}
                    {loading ?
                        <Loader />
                    :
                        <>
                            <table className={'сreoProfits'}>
                                <tbody>
                                    <tr>
                                        <td>Всего записей за месяц</td>
                                        <td>{creoProfitsStore.length}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={'filtersCreoProfit'}>
                                <div>Выгрузить статистику</div>
                                <Select 
                                    styles={colorStyles} 
                                    menuPortalTarget={document.body} 
                                    className={'options'}
                                    options={monthOptions}
                                    onChange={option => handleMonthYearChange(option.value, selectedYear)}
                                    placeholder="Month"
                                />
                                <Select 
                                    styles={colorStyles} 
                                    menuPortalTarget={document.body} 
                                    className={'options'}
                                    options={yearOptions}
                                    onChange={option => handleMonthYearChange(selectedMonth, option.value)}
                                    placeholder="Year"
                                />
                                <button className={'webBtnSort'} onClick={downloadData}>Download</button>
                            </div>
                            <div className={'filtersCreoProfit'}>
                                <div className={'textNew'}>
                                    <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} options={groupedOptions} onChange={selectedOption => setMultiOptionCreativeName(selectedOption.map(el => el.value))/*(/^[A-Z][A-Z]$/gm).test(selectedOption[0]?.value) ? setMultiOptionGeo(selectedOption.map(el => el.value)) : (/^(android|ios)$/gm).test(selectedOption[0]?.value) ? setMultiOptionOs(selectedOption.map(el => el.value)) : setMultiOptionCreativeName(selectedOption.map(el => el.value))*/} isMulti />
                                </div>
                                {multiOptionCreativeName.length !== 0 && (
                                    <div className={'textNew'}>
                                        <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} options={groupedOptions} onChange={selectedOption => setMultiOptionGeo(selectedOption.map(el => el.value))/*(/^[A-Z][A-Z]$/gm).test(selectedOption[0]?.value) ? setMultiOptionGeo(selectedOption.map(el => el.value)) : (/^(android|ios)$/gm).test(selectedOption[0]?.value) ? setMultiOptionOs(selectedOption.map(el => el.value)) : setMultiOptionCreativeName(selectedOption.map(el => el.value))*/} isMulti />
                                    </div>
                                )}
                                {multiOptionGeo.length !== 0 && (
                                    <div className={'textNew'}>
                                        <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} options={groupedOptions} onChange={selectedOption => setMultiOptionGeo(selectedOption.map(el => el.value))/*(/^[A-Z][A-Z]$/gm).test(selectedOption[0]?.value) ? setMultiOptionGeo(selectedOption.map(el => el.value)) : (/^(android|ios)$/gm).test(selectedOption[0]?.value) ? setMultiOptionOs(selectedOption.map(el => el.value)) : setMultiOptionCreativeName(selectedOption.map(el => el.value))*/} isMulti />
                                    </div>
                                )}
                            </div>
                            <table className={'tableCreoProfits'}>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('template')}>Template</th>
                                        <th onClick={() => handleSort('creativeName')}>Creative Name</th>
                                        {multiOptionCreativeName.length !== 0 && <th onClick={() => handleSort('country')}>Country</th>}
                                        {multiOptionGeo.length !== 0 && <th onClick={() => handleSort('os')}>OS</th>}
                                        <th onClick={() => handleSort('installs')}>Installs</th>
                                        <th onClick={() => handleSort('ipm')}>IPM</th>
                                        <th onClick={() => handleSort('spend')}>Spend</th>
                                        <th onClick={() => handleSort('regs')}>Regs</th>
                                        <th onClick={() => handleSort('deps')}>Deps</th>
                                        <th onClick={() => handleSort('averagePayout')}>Av.Payout</th>
                                        <th onClick={() => handleSort('income')}>Income</th>
                                        <th onClick={() => handleSort('profit')}>Profit</th>
                                        <th onClick={() => handleSort('roi')}>ROI</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {/* {multiOptionCreativeName.length === 0 && multiOptionGeo.length === 0 && multiOptionOs.length === 0
                                    ? creoProfitsStore.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.template}</td>
                                            <td>{item.creativeName.slice(0, 6)}</td>
                                            <td>{item.installs}</td>
                                            <td>{item.ipm.toFixed(2)}</td>
                                            <td>{item.spend.toFixed(2)}$</td>
                                            <td>{item.regs}</td>
                                            <td>{item.deps}</td>
                                            <td>{item.averagePayout}$</td>
                                            <td>{item.income.toFixed(2)}$</td>
                                            <td>{item.profit.toFixed(2)}$</td>
                                            <td>{item.roi.toFixed(2)}%</td>
                                        </tr>
                                    ))
                                :  */}
                                    {filteredCreoProfitsStore.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.template}</td>
                                            <td>{item.creativeName.slice(0, 6)}</td>
                                            {multiOptionCreativeName.length !== 0 && <td>{item.country}</td>}
                                            {multiOptionGeo.length !== 0 && <td>{item.os}</td>}
                                            <td>{item.installs}</td>
                                            <td>{item.ipm.toFixed(2)}</td>
                                            <td>{item.spend.toFixed(2)}$</td>
                                            <td>{item.regs}</td>
                                            <td>{item.deps}</td>
                                            <td>{item.averagePayout}$</td>
                                            <td>{item.income.toFixed(2)}$</td>
                                            <td>{item.profit.toFixed(2)}$</td>
                                            <td>{item.roi}%</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    }
                {/* </>
            } */}
        </div>
    )
}

export default CreoProfits

        // Функция для добавления ключа, если он отсутствует
        // const addKeyIfNotExists = (key) => {
        //     setKeysToMatch(prevKeys => {
        //         if (!prevKeys.includes(key)) {
        //             return [...prevKeys, key]
        //         }
        //         return prevKeys
        //     })
        // }

        // // Функция для удаления ключа, если он присутствует
        // const removeKeyIfExists = (key) => {
        //     setKeysToMatch(prevKeys => {
        //         return prevKeys.filter(k => k !== key)
        //     })
        // }

        // // Функция для добавления объекта в groupedOptions
        // const addGroupedOptionIfNotExists = (option) => {
        //     setGroupedOptions(prevOptions => {
        //         if (!prevOptions.some(o => o.label === option.label)) {
        //             return [...prevOptions, option]
        //         }
        //         return prevOptions
        //     })
        // }

        // // Функция для удаления объекта из groupedOptions
        // const removeGroupedOption = (label) => {
        //     setGroupedOptions(prevOptions => {
        //         return prevOptions.filter(o => o.label !== label);
        //     })
        // }

        // addKeyIfNotExists('creativeName')

        // if (multiOptionCreativeName.length === 0) {
        //     // Если массив опустел, удаляем 'country', добавляем 'Creos'
        //     removeKeyIfExists('country')
        //     addGroupedOptionIfNotExists({ label: 'Creos', options: creativeNames})
        // } else if (multiOptionCreativeName.length > 0) {
        //     // Если массив не пуст, добавляем 'country', убираем 'Creos'
        //     addKeyIfNotExists('country')
        //     removeGroupedOption('Creos')
        // }
    
        // // Логика для multiOptionGeo
        // if (multiOptionGeo.length === 0) {
        //     // Если массив опустел, удаляем 'country', добавляем 'Geo'
        //     removeKeyIfExists('country')
        //     addGroupedOptionIfNotExists({label: 'Geo', options: geos})
        // } else if (multiOptionGeo.length > 0) {
        //     // Если массив не пуст, добавляем 'country', убираем 'Geo'
        //     addKeyIfNotExists('os')
        //     removeGroupedOption('Geo')
        // }
    
        // // Логика для multiOptionOs
        // if (multiOptionOs.length === 0) {
        //     // Если массив опустел, удаляем 'os', добавляем 'Os'
        //     removeKeyIfExists('os')
        //     addGroupedOptionIfNotExists({label: 'Os', options: os})
        // } else if (multiOptionOs.length > 0) {
        //     // Если массив не пуст, добавляем 'os', убираем 'Os'
        //     // addKeyIfNotExists('os')
        //     removeGroupedOption('Os')
        // }

        // {multiOptionGeo.length !== 0 && (
        //     <span className={'text'}>
        //         <Select styles={colorStyles} menuPortalTarget={document.body} className={'options'} options={groupedOptions} onChange={selectedOption => setMultiOptionOs(selectedOption.map(el => el.value))/*(/^[A-Z][A-Z]$/gm).test(selectedOption[0]?.value) ? setMultiOptionGeo(selectedOption.map(el => el.value)) : (/^(android|ios)$/gm).test(selectedOption[0]?.value) ? setMultiOptionOs(selectedOption.map(el => el.value)) : setMultiOptionCreativeName(selectedOption.map(el => el.value))*/} isMulti />
        //     </span>
        // )}